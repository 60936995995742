

.p-footer {
  font-size: 11px;
  padding: 0;
  width: 100%;

  a {
    color: $footer-text;
    text-decoration: underline;
  }
}

.p-footer__body {
  padding: 13px 0;
  align-items: center;
  background-color: $white;
  color: $footer-text;
  display: flex;
  min-height: 40px
}

.p-footer__company {
  grid-column: 1;
  grid-row: 1;
  margin: 0;
  width: 5.3rem;
}

.p-footer__copyright {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
  grid-column: 2 / span 2;
  @media(min-width: $lg) {
    justify-content: space-between;
  }
  span:not(.contact-info) {
    margin-right: 32px;
  }
  a, .contact-info {
    margin-right: 32px;
    + a, + .contact-info {
      margin-left: 0;
    }
  }
}

.page-footer.darkTheme {
  background-color: $dark-primary;
  border-top: solid 1px #262930;

  .p-footer {
    background-color: $dark-primary;
    color: $dark-primary-text;

    .p-footer__body {
      background-color: $dark-primary;
      color: $dark-primary-text;
      border-top: solid 1px $dark-border;
    }

    a {
      color: $dark-primary-text;
    }
  }
}
