@import "../settings/settings.mat-variables";

.mat-mdc-form-field-icon-prefix span {
  padding-left: 5px;
}

.form-error-msg {
  color: $warn-color;
}

.form-warn-msg {
  color: $warn-color;
}

.form-actions {
  display: flex;

  .right-side {
    margin-left: auto;
  }
}

.file-input {
  input {
    position: absolute;
    visibility: hidden;
  }

  label {
    cursor: pointer;

    button {
      pointer-events: none;
    }
  }
}

.checkbox-form-margin {
  margin-bottom: 10px;
}

.mb-form {
  margin-bottom: 19px;
}

.field-required {
  color: $warn-color;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 17px;
}

.mobile-only,
.mat-divider.mobile-only {
  display: none;
}

@media (max-width: $sm) {
  .content-block {
    .display-flex {
      flex-wrap: wrap;
    }

    .content-block-header {
      font-size: 16px;
      font-weight: 600;
      padding-top: 1rem;
    }
  }

  .mobile-only,
  .mat-divider.mobile-only {
    display: block;
  }

  .mobile-hide {
    display: none;
  }
}

// Dark Theme
.darkTheme {
  .mat-mdc-form-field {
    color: $dark-primary-text;
    //background-color: $dark-primary;

    &.mat-focused {
      color: $dark-primary-text !important;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $dark-input-border-focus !important;
      }
    }

    .mat-mdc-input-element,
    .mat-mdc-input-element::placeholder {
      color: $dark-primary-text;
      caret-color: $dark-primary-text;
    }
    .mat-mdc-form-field-label {
      color: $dark-primary-text;
    }
    .mat-mdc-form-field-underline {
      background-color: $dark-primary-text;
    }
    .mat-mdc-form-field-ripple {
      background-color: $dark-primary-text;
    }
    .mat-mdc-form-field-required-marker {
      color: $dark-primary-text;
    }

    .mat-mdc-icon-button {
      background-color: $dark-tongo-button-secondary;
      color: $dark-tongo-button-secondary-text;

      &:hover {
        background: $dark-tongo-button-secondary-hover;
      }
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: $dark-primary-text !important;
    }
  }

  .mat-mdc-form-field-appearance-legacy {
    .mat-mdc-form-field-label,
    .mat-mdc-form-field-appearance-legacy {
      color: $dark-primary-text;
    }
  }

  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-checkmark-color: $dark-tongo-button-primary-text;
    --mdc-checkbox-selected-focus-icon-color: $dark-tongo-button-primary-text;
    --mdc-checkbox-selected-hover-icon-color: $dark-tongo-button-primary-text;
    --mdc-checkbox-selected-icon-color: $dark-tongo-button-primary-text;
    --mdc-checkbox-selected-pressed-icon-color: $dark-tongo-button-primary-text;
    --mdc-checkbox-unselected-focus-icon-color: $dark-tongo-button-primary-text;
    --mdc-checkbox-unselected-hover-icon-color: $dark-tongo-button-primary-text;
    --mdc-checkbox-disabled-selected-icon-color: $dark-tongo-button-disabled;
    --mdc-checkbox-disabled-unselected-icon-color: $dark-tongo-button-disabled;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  }

  //.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  //  background-color: $dark-tongo-button-primary !important;
  //}
  //
  //.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  //  background-color: $dark-tongo-button-primary-text !important;
  //}

  .mat-checkbox-ripple .mat-ripple-element,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $dark-tongo-button-primary !important;
  }
}
