$sidenavWidth: 250px;

.sidenav-sidenav {
  // background-image: linear-gradient(180deg, #261C04 0%, #6d0dd5 80%);
  height: 100vh;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  top: 0;
  width: $sidenavWidth;
  box-shadow: 0px 4px 4px 0px #00000040;

  $version-number-line-height: 1.5;
  $version-number-padding: 1.75rem;
  $version-number-size: 0.6875rem;

  .menu-logo-container {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .sidenav-sidenav__logo {
      height: 30px;
      margin-top: 5px;
    }
    .menu-button {
      height: 40px;
      width: 40px;
      min-width: 40px;
      margin: 0;
      border-radius: 4.44px;
      border: 1px solid $grey6 !important;

      &.mdc-button.mat-mdc-button:not(:disabled) {
        color: $grey6;
      }

      .menu-button-close-icon {
        height: 40px;
        width: 40px;
        min-width: 40px;
        font-size: 38px;
        padding: 0;
        margin: 0;
      }
    }
  }

  .mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    padding: 0 1rem;
    width: auto;
  }

  .mat-drawer-header {
    align-items: center;
    color: $black;
    display: flex;
    flex-direction: row;
    height: $navbar-height-xs;
    justify-content: left;
    text-align: left;
    @media (min-width: $sm-screen) {
      height: $navbar-height;
    }

    img {
      box-sizing: border-box;
      height: calc(#{$navbar-height-xs} - (#{$version-number-size} * #{$version-number-line-height}));
      padding: 0.5rem;
      width: auto;
      @media (min-width: $sm-screen) {
        height: calc(#{$navbar-height} - (#{$version-number-size} * #{$version-number-line-height}));
      }
    }
  }

  .version-number {
    color: #fff;
    display: block;
    font-size: $version-number-size;
    padding: $version-number-padding 0;
  }

  .sidenav-content {
    margin-top: 2rem;
    margin-bottom: auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .mat-divider {
      margin-top: 16px;
      margin-bottom: 16px;

      &:last-of-type {
        padding-bottom: 74px;
      }
    }
  }

  .mat-mdc-nav-list {
    padding-top: 0;

    .mat-mdc-list-item {
      padding: 0 0.5rem;

      .mat-mdc-list-item-unscoped-content {
        padding-bottom: 0.2rem;
        display: flex;
      }

      &.active {
        background-color: #571b98;
        border-radius: 0.25rem;

        // color: rgba(0,0,0,.87);
      }

      .navbar-item-text, .material-icons-outlined {
        color: $white-color;
      }
    }
  }

  //.mat-icon {
  //  font-size: 20px;
  //  height: 20px;
  //  width: 20px;
  //}

  .navbar-item-text {
    font-size: 1rem;
    padding-left: 0.6em;
  }

  .mat-expansion-panel {
    background-color: transparent;
    box-shadow: none;
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-header.mat-expanded {
    height: 2rem;
    padding: 0;
    padding-right: 0.5rem;
  }

  .mat-expansion-panel-header-title {
    font-size: 16px;
  }

  .mat-expansion-indicator::after {
    color: #B5B5B5;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-list-base {
    .mat-list-item,
    .mat-list-option {
      .mat-list-item-content {
        padding: 0 4px;
        width: 100%;
      }
    }
  }

}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 56px;
}

.sidenav-container.layout-tablet .mat-drawer-opened {
  width: $sidenavWidth + 40px;
  height: 100vh;
}

.sidenav-container.layout-mobile .mat-drawer-opened {
  width: calc(100vw);
  height: 100vh;
}

.sidenav-container.layout-tablet,
.sidenav-container.layout-mobile {
  .sidenav-sidenav {
    .mat-drawer-inner-container {
      .sidenav-content {
        margin-top: 0;

        .menu-logo-container {
          margin: 20px 0;
        }
      }
    }
  }
}

.sidenav-container.layout-mobile {
  .sidenav-sidenav,
  .sidenav-footer {
    width: calc(100vw - 40px);
  }
}

// Dart Theme
.darkTheme {
  .mat-divider {
    border-top-color: $dark-border !important;
  }

  .sidenav-sidenav__logo,
  .agreement-logo {
    filter: grayscale(1) invert(1);
  }
}
