@import "../settings/settings.mat-variables";

.table-filters {
  background: #e0e0e0;
  border-radius: 0.5rem 0.5rem 0 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0.5rem 0.31rem;
  width: 100%;

  .table-filter-btn {
    margin-bottom: auto;
    margin-top: auto;
  }

  &.table-filters-full {
    flex-wrap: wrap;
    @media (min-width: $xl-screen) {
      flex-wrap: nowrap;
    }

    .table-filter {
      width: calc(50% - 2rem);
      @media (min-width: $md-screen) {
        width: calc(25% - 2rem);
      }
    }

    // if 5 items
    .table-filter:first-child:nth-last-child(5),
    .table-filter:first-child:nth-last-child(5) ~ .table-filter {
      @media (min-width: $md-screen) {
        width: calc(33% - 2rem);
      }

      @media (min-width: $xl-screen) {
        width: calc(20% - 2rem);
      }
    }

    // if 6 items
    .table-filter:first-child:nth-last-child(6),
    .table-filter:first-child:nth-last-child(6) ~ .table-filter {
      @media (min-width: $lg-screen) {
        width: calc(33% - 2rem);
      }

      @media (min-width: $xl-screen) {
        width: calc(16.6% - 2rem);
      }
    }

    // if 7 items
    .table-filter:first-child:nth-last-child(7),
    .table-filter:first-child:nth-last-child(7) ~ .table-filter {
      @media (min-width: $lg-screen) {
        width: calc(25% - 2rem);
      }

      @media (min-width: $xl-screen) {
        width: calc(14.28% - 2rem);
      }
    }

    // if 8 items
    .table-filter:first-child:nth-last-child(8),
    .table-filter:first-child:nth-last-child(8) ~ .table-filter {
      @media (min-width: $lg-screen) {
        width: calc(25% - 2rem);
      }

      @media (min-width: $xl-screen) {
        width: calc(12.5% - 2rem);
      }
    }

    //
  }

  .mat-form-field-appearance-fill {
    .mat-mdc-text-field-wrapper {
      background: #f4f4f4;
      border: 1px solid #d1d1d1;
      border-radius: 4px;
    }
  }

  .mdc-line-ripple {
    display: none;
    height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
  }

  .mat-mdc-floating-label {
    max-width: 130%;
  }
}

.table-filter {
  margin-left: 1rem;
  margin-right: 1rem;
}

//.table-filter--right {
//  margin-left: auto;
//
//  @media (max-width: $md-screen) {
//    margin-left: $sm-margin;
//  }
//}
