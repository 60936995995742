/**
 * src/styles.scss
 *
 * @author Alan Moore <alan@payfully.co>
 * @date 7/19/2021
 * @copyright Payfully, 2021
 *
 */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@import "@angular/material/theming";
@import "app/styles/settings/settings.mat-variables";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$font-family: "\'IBM Plex Sans\', \'Helvetica Neue\', Helvetica, Arial, sans-serif";
$custom-typography: mat.define-typography-config(
  $font-family: $font-family,
  $headline-1:  mat.private-typography-config-level-from-mdc(headline1, (font-family: $font-family, letter-spacing: 0)),
  $headline-2:  mat.private-typography-config-level-from-mdc(headline2, (font-family: $font-family, letter-spacing: 0)),
  $headline-3:  mat.private-typography-config-level-from-mdc(headline3, (font-family: $font-family, letter-spacing: 0)),
  $headline-4:  mat.private-typography-config-level-from-mdc(headline4, (font-family: $font-family, letter-spacing: 0)),
  $headline-5:  mat.private-typography-config-level-from-mdc(headline5, (font-family: $font-family, letter-spacing: 0)),
  $headline-6:  mat.private-typography-config-level-from-mdc(headline6, (font-family: $font-family, letter-spacing: 0)),
  $subtitle-1:  mat.private-typography-config-level-from-mdc(subtitle1, (font-family: $font-family, letter-spacing: 0)),
  $subtitle-2:  mat.private-typography-config-level-from-mdc(subtitle2, (font-family: $font-family, letter-spacing: 0)),
  $body-1:      mat.private-typography-config-level-from-mdc(body1, (font-family: $font-family, letter-spacing: 0)),
  $body-2:      mat.private-typography-config-level-from-mdc(body2, (font-family: $font-family, letter-spacing: 0)),
  $caption:     mat.private-typography-config-level-from-mdc(caption, (font-family: $font-family, letter-spacing: 0)),
  $button:      mat.private-typography-config-level-from-mdc(button, (font-family: $font-family, letter-spacing: 0)),
  $overline:    mat.private-typography-config-level-from-mdc(overline, (font-family: $font-family, letter-spacing: 0)),
);

@import "app/styles/settings/settings.mat-variables.scss";

$tongo-palette: (
  50: lighten($primary-color, 50%),
  100: lighten($primary-color, 40%),
  200: lighten($primary-color, 30%),
  300: lighten($primary-color, 20%),
  400: lighten($primary-color, 10%),
  500: $primary-color,
  600: darken($primary-color, 7%),
  700: darken($primary-color, 14%),
  800: darken($primary-color, 21%),
  900: darken($primary-color, 28%),
  A100: darken($primary-color, 35%),
  A200: darken($primary-color, 42%),
  A400: darken($primary-color, 49%),
  A700: darken($primary-color, 56%),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$tongo-palette-main: mat.define-palette($tongo-palette);


$admin-theme: mat.define-light-theme(
  (
    typography: $custom-typography,
    color: (
      primary: $tongo-palette-main,
      accent: $accent-palette,
      warn: $warn-palette,
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admin-theme);

// SETTINGS
@import "app/styles/settings/settings.mat-variables";

.mat-typography {
  font-family: $font-family;

  h1, h3 {
    font-weight: 400;
  }
}

// TOOLS
@import "flexboxgrid/css/flexboxgrid.css";
@import "ngx-toastr/toastr";

// GENERIC

// ELEMENTS
@import "app/styles/elements/elements.page";

// OBJECTS
@import "app/styles/objects/objects.form";
@import "app/styles/objects/objects.filters";
@import "app/styles/objects/objects.headings";
@import "app/styles/objects/objects.navbar";
@import "app/styles/objects/objects.sidenav";
@import "app/styles/objects/objects.footer";
@import "app/styles/objects/objects.login";

// COMPONENTS
@import "app/styles/components/components.mat-card";
@import "app/styles/components/components.mat-drawer";
@import "app/styles/components/components.mat-tab";
@import "app/styles/components/components.mat-table";
@import "app/styles/components/components.role-permission";
@import "app/styles/components/components.mat-paginator";
@import "app/styles/components/components.mat-dialog";
@import "app/styles/components/components.mat-card-header";
@import "app/styles/components/components.mat-button";
@import "app/styles/components/components.mat-button-toggle";
@import "app/styles/components/components.mat-icon";
@import "app/styles/components/components.mat-slide-toggle";

// UTILITIES
@import "app/styles/utilities/utilities.widths";
