@use '@material/menu-surface/mixins' as mdc-menu-surface;
@use '@material/list/evolution-mixins' as mdc-list;
@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/mdc-helpers/mdc-helpers';

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  @include mdc-helpers.using-mdc-theme($config) {
    @include mdc-menu-surface.core-styles(mdc-helpers.$mdc-theme-styles-query);
    @include mdc-list.without-ripple(mdc-helpers.$mdc-theme-styles-query);
  }
}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2018-config(
      theming.get-typography-config($config-or-theme));
  @include mdc-helpers.using-mdc-typography($config) {
    @include mdc-menu-surface.core-styles(mdc-helpers.$mdc-typography-styles-query);

    .mat-mdc-autocomplete-panel {
      // Note that we include this private mixin, because the public one adds
      // a bunch of styles that we aren't using for the autocomplete panel.
      @include mdc-list.list-base(mdc-helpers.$mdc-typography-styles-query);
    }
  }
}

@mixin density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-autocomplete') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
