@use '@material/textfield' as mdc-textfield;
@use '@material/theme/theme' as mdc-theme;
@use '@material/typography' as mdc-typography;
@use '@material/textfield/variables' as mdc-textfield-variables;

@use '../core/theming/theming';
@use '../core/mdc-helpers/mdc-helpers';
@use './form-field-sizing';

@mixin private-form-field-subscript() {
  // Wrapper for the hints and error messages.
  .mat-mdc-form-field-subscript-wrapper {
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 mdc-textfield-variables.$padding-horizontal;
  }

  .mat-mdc-form-field-subscript-dynamic-size {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      position: static;
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    content: '';
    display: inline-block;
    height: 16px;
  }

  .mat-mdc-form-field-bottom-align.mat-mdc-form-field-subscript-dynamic-size::before {
    content: unset;
  }

  .mat-mdc-form-field-hint-end {
    order: 1;
  }

  // Clears the floats on the hints. This is necessary for the hint animation to work.
  .mat-mdc-form-field-hint-wrapper {
    display: flex;
  }

  // Spacer used to make sure start and end hints have enough space between them.
  .mat-mdc-form-field-hint-spacer {
    flex: 1 0 form-field-sizing.$mat-form-field-hint-min-space;
  }

  // Single error message displayed beneath the form field underline.
  .mat-mdc-form-field-error {
    display: block;
  }
}

@mixin private-form-field-subscript-color() {
  // MDC does not have built-in error treatment.
  .mat-mdc-form-field-error {
    @include mdc-theme.prop(color, mdc-textfield.$error);
  }
}

// We need to define our own typography for the subscript because we don't include MDC's
// helper text in our MDC based form field
@mixin private-form-field-subscript-typography($config-or-theme) {
  $config: theming.get-typography-config($config-or-theme);

  // The subscript wrapper has a minimum height to avoid that the form-field
  // jumps when hints or errors are displayed.
  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align::before {
    @include mdc-typography.typography(caption, $query: mdc-helpers.$mdc-typography-styles-query);
  }
}
