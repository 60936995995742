@use '@material/theme/theme' as mdc-theme;

@use '../theming/theming';
@use '../typography/typography';
@use '../mdc-helpers/mdc-helpers';

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  @include mdc-helpers.using-mdc-theme($config) {
    .mat-mdc-optgroup-label {
      // Since this will usually be rendered in an overlay,
      // we have to explicitly set the default color.
      @include mdc-theme.prop(color, text-primary-on-background);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2018-config(
      theming.get-typography-config($config-or-theme));
}

@mixin density($config-or-theme) {
  $density-scale: theming.get-density-config($config-or-theme);
}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-optgroup') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
