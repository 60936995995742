@import "../settings/settings.mat-variables";

.role-permission {
  h1 {
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
  }

  .info-permission {
    color: $gray3;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 40px;
  }

  .content {
    gap: 24px;

    .full-permissions {
      width: 100%;
      border-radius: 8px;
      border: 1px solid $grey2;

      .header-card {
        padding: 24px;
        border-bottom: 1px solid $grey2;
        background: $grey7;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }

      .content {
        padding: 24px;

        .permissions-list {
          color: $gray3;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 16px;
        }

        .permission-name {
          padding-left: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-bottom: 24px;
          font-size: 15px;

          .permission-size {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }

        .permission-name.last {
          margin-bottom: 0px;
        }

      }
    }
  }
}

.role-modal {
  overflow-y: auto;
}

@media (max-width: $sm) {
  .role-permission {
    .content {
      flex-direction: column;

      .full-permissions {
        .content {
          padding-left: 16px;
          padding-right: 16px;
        }
        .permission-name {
          padding-left: 16px;
        }
      }
    }
  }
}
