@import "../settings/settings.mat-variables";

.mat-icon.mat-icon--crossed-out {
  position: relative;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: -1px;
    top: 10px;
    width: 110%;
    height: 2px;
    background: $white-color;
    transform: rotate(45deg);
    border-top: 2px solid $accent-color;
  }

  &.mat-warn:before {
    border-color: $warn-color;
  }
  &.mat-accent:before {
    border-color: $accent-color;
  }
  &.mat-primary:before {
    border-color: $primary-color;
  }
}
