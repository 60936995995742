.full-width {
  width: 100%;
}

.t-a-center {
  text-align: center;
}

.slogan.f-z-15 {
  font-size: 15px;
  margin-bottom: 0;
}

.slogan {
  color: $asbestos;
  font-size: 18px;
  margin-bottom: 20px;
}

.url-text {
  color: $primary-purple;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.24px;
  text-decoration: none;
}

.p-error-message {
  margin-bottom: 1.5rem;;
  color: $alizarin;
}

.p-error-message:first-letter {
  text-transform: uppercase;
}

.c-p-p {
  color: $primary-purple;
}

.t_a-r {
  text-align: right;
}

.f-s-64{
  font-size: 64px;
}
.f-s-32{
  font-size: 32px;
}

.f-s-16 {
  font-size: 16px;
}

.m-l-auto {
  margin-left: auto;
}
.m-t-a {
  margin-top: auto;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-0 {
  margin-left: 0;
}

.m-b-16 {
  margin-bottom: 16px;
}
.f-z-14 {
  font-size: 14px;
  font-weight: 600;
}
.m-t-24 {
  margin-top: 24px;
}

.cursor-default {
  cursor: default;
}

mat-icon.dashboard {
  color: $asbestos;
}

.display-flex {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.csv-block {
  margin-left: auto;

  label {
    cursor: pointer;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  button {
    pointer-events: none;
  }
}

.file-block {
  label {
    cursor: pointer;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  a {
    pointer-events: none;
  }
}

.tongo-link {
  color: $primary-purple;
}

@media (max-width: $sm) {
  .mat-mdc-card-content .mat-mdc-card {
    .display-flex {
      display: block;
    }

    .mat-mdc-card-content {
      overflow-x: scroll;
    }
  }
}

// Dark Theme
.darkTheme {
  .tongo-link {
    color: $dark-primary-text;
  }
}
