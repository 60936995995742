.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
}

.mat-mdc-card {
  padding: 32px;
  box-shadow: none;

  .mat-mdc-card-content {
    padding: 0;


    &:first-child {
      padding-top: 0;
    }
  }

  .mat-mdc-card-title {
    font-size: 34px;
    margin-bottom: 1.5rem;
    padding: 0;
    font-weight: 300;
  }
}

.mat-card-actions {
  margin-bottom: -8px;
  padding-bottom: 0;
}

.mat-mdc-card-content {
  margin-bottom: 16px;
}

.card-actions-with-margin {
  button:not(:first-child) {
    margin-left: 10px;
  }
}

.card-header-with-balance {
  align-items: center;
  display: flex;
  width: 100%;

  .balance-wrapper {
    display: flex;
    margin-left: auto;

    .balance-items {
      margin-right: 20px;

      .balance-item {
        display: flex;
        width: 100%;
        margin: auto 0;

        &:last-child {
          margin-bottom: 15px;
        }

        .balance-item--title {
          font-weight: bold;
          min-width: 65px;
          text-align: right;
        }

        .balance-item--value {
          margin-left: 5px;
        }
      }
    }

    .apply-payment,
    .manual-payout,
    .schedule-payment {
      height: fit-content;
      margin-left: 1.5rem;
    }

    .apply-payment {
      margin-right: 0;
    }
  }
}

.balance-wrapper {
  display: flex;
  margin-left: auto;

  .balance-items {
    display: flex;

    .balance-item {
      display: flex;
      margin: auto 0;

      &:not(:last-child) {
        margin-right: 1.75rem;
      }

      .balance-item--title {
        font-weight: 600;
      }

      .balance-item--value {
        margin-left: 0.5em;
      }
    }
  }
}

.mat-header-with-buttons {
  display: flex;
  margin-bottom: 5px;

  .header-button {
    margin-left: auto;
  }
}

mat-icon.card-title-icon {
  $size: 19px;

  font-size: $size;
  height: $size;
  width: $size;
  margin-left: 2px;
}

// Dark Theme
.darkTheme {
  .mat-mdc-card {
    color: $dark-primary-text;
    background-color: $dark-primary;

    .mat-mdc-card-header {
      color: $dark-primary-text;
    }
  }

  .support,
  .support-email {
    background-color: $dark-info-message !important;
  }
}

@media (max-width: $sm) {
  .mat-mdc-card {
    padding: 32px 20px;
  }
}
