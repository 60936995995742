@import "../settings/settings.mat-variables";

h3.page-header {
  color: #261c04;
  display: flex;
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 1.75rem;
  justify-content: space-between;
  left: 5rem;
  line-height: 2rem;
  margin-bottom: 0;
  position: absolute;
  top: 1rem;
  width: calc(100% - 9rem);
  margin-top: 0;

  h1 {
    margin: auto 0 auto $md-margin;
    color: $white-color;
  }
}

h3.page-header--with-subhead {
  font-size: 1rem;
  line-height: 1.5rem;
  top: 1.25rem;
}

.page-subheader {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
  width: 100%;
  display: flex;
  font-family: $font-family;

  .card-header-with-balance {
    font-family: $font-family;
  }

  button {
    margin-right: 1.25rem;
  }

  .mat-mdc-icon-button {
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 1.75rem;
    justify-content: center;
    line-height: 1.75rem;
    width: 1.75rem;
    padding: 0;

    .mat-icon {
      height: 1rem;
      line-height: 1rem;
      width: 1rem;
    }
  }
}

// darkTheme
.darkTheme.content-wrapper {
  background-color: $dark-primary;
  color: $dark-primary-text;
}
