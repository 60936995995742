@import "../settings/settings.mat-variables";

@mixin font-body-p1 {
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.mat-mdc-paginator-container {
  @include font-body-p1;
  color: $gray3;

  @media (max-width: $xs) {
    .mat-mdc-paginator-range-label {
      margin: 0;
    }
  }

  .mat-mdc-select-value {
    @include font-body-p1;
    color: $gray3;
  }

  .mdc-icon-button:not(:disabled) {
      color: $gray3;
  }

  .mat-mdc-paginator-page-size, .mat-mdc-paginator-range-actions {
    margin-bottom: 10px;
  }
}

@media (max-width: $sm) {
  .mat-mdc-paginator-container {
    flex-direction: column;
  }
}
