@import "../settings/settings.mat-variables";

html,
body {
  height: 100%; // from Material base theme
}

body {
  margin: 0; // from Material base theme
}

// .content-wrapper {
//   padding: $md-padding;
// }

.page-body,
.page-body.layout-desktop {
  background-color: $white;
  min-height: 100vh;
  margin-left: $navbar-width !important;
  width: calc(100vw - $navbar-width);

  &.darkTheme {
    background-color: $dark-primary;
  }
}

.padding-0-15 {
  @media (max-width: $xs-small) {
    padding: 0 15px;
  }
}

.page-body.layout-tablet,
.page-body.layout-mobile {
  margin-left: 0 !important;
  width: calc(100vw) !important;
}

.page-block {
  padding: $md-padding;
  margin-bottom: $md-margin;
  box-shadow: $box-shadow;
  border-radius: $default-border-radius;
}

.info {
  padding: 2rem;
  height: calc(100% - 185px);
  max-width: 420px;
  position: absolute;
  right: 0;
  border-left: 1px solid $smoke2;
  background: $white;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.07);
}

a.removed-underline {
  text-decoration:none;
}

.padding-important {
  padding: 0 !important;
}
