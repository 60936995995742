@use "sass:map";
@use "@angular/material" as mat;

$font-family: "IBM Plex Sans", "Helvetica Neue", helvetica, arial, sans-serif;

// Palettes
$primary-palette: mat.define-palette(mat.$indigo-palette, 500);
$accent-palette: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$warn-palette: mat.define-palette(mat.$red-palette);

// Colors
$alizarin: #E74C3C;
$bright-purple: #B072FF;
$primary-purple: #6D11D6;
$midnightblue: #293E4F;
$dark-purple: #49009B;
$dark-grey: #1F2125;
$grey: #E7E7E9;
$grey2: #D9D9D9;
$grey3: #6F6A6A;
$grey4: #34495E;
$grey5: #7F8C8D;
$grey6: #B5B5B5;
$grey7: #F8F7F9;
$smoke: #B1B1B1;
$smoke2: #D2D2D2;
$asbestos: #7F8C8D;
$white2: #e6e6e6;
$white3: #F4F4F4;
$white: #FFF;
$black: #000;
$light-purple1: #E0C2FF;
$primary-color: #6d0dd5;
$accent-color: #dadada;
$warn-color: map.get(mat.$red-palette, 500);
$white-color: white;
$gray: #737373;
$gray2: #ccc;
$gray3: #535353;
$bg-grey: rgb(244 244 244);
$bg-card: #fafafa;
$highlighter-success: rgb(48 255 0 / 27%);
$turquoise: #1ABC9C;
$highlighter-warn: rgba($warn-color, 27%);
$selectedRow: #e4eef8;
$hoverRow: aliceblue;
$selectedLink: #F7EFFF;
$greenBoxText: #066F5A;
$greenBoxGround: #E4FBE6;
$redBoxText: #C73831;
$redBoxGround: #FDEFEE;
$yellowBoxText: #D35400;
$yellowBoxGround: #FEF7D9;
$grayBoxText: #585858;
$grayBoxGround: #F6F6F6;

$dark-gray-color9: #b5b5b5;

$tongo-button-primary: $primary-purple;
$tongo-button-primary-text: $white;
$tongo-button-primary-hover: $black;
$tongo-button-secondary: $white;
$tongo-button-secondary-border: $tongo-button-primary;
$tongo-button-secondary-text: $tongo-button-primary;
$tongo-button-secondary-hover: $tongo-button-primary;
$tongo-button-secondary-text-hover: $white;
$tongo-button-tertiary: $white;
$tongo-button-tertiary-text: $tongo-button-primary;
$tongo-button-tertiary-disabled: #E0C2FF;
$tongo-button-disabled: #D9D9D9;
$tongo-button-disabled-text: #7F8C8D;
$tongo-button-delete: #C73831;

$default-button-border-radius: 3px;

$tongo-table-background: $white;
$tongo-table-text-color: $gray3;
$tongo-table-expand-background: #F9F9F9;

$purple: #6D11D6;
$dark-purple: #49009B;
$light-purple: #B072FF;

$Lighter-purple: #F9F3FF;

$zero-state-bg2: #F8F7F9;

$footer-text: #535353;

// Screen sizes
$xs-small: 420px;
$xs: 560px;
$sm: 760px;
$md: $sm;
$lg: 960px;
$xl: 1260px;
$xxl: 1660px;

$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 1024px;
$xl-screen: 1440px;
$xxl-screen: 1920px;

$mobile-screen: $sm;

// Font sizes
$sm-font: 12px;
$md-font: 14px;
$lg-font: 18px;

// Margin
$sm-margin: 10px;
$md-margin: 20px;
$lg-margin: 30px;

// Padding
$sm-padding: 10px;
$md-padding: 20px;
$lg-padding: 30px;

// Const
$navbar-height: 64px;
$navbar-height-xs: 56px;
$full-page-height: $navbar-height + ($md-padding * 2);
$full-page-height-xs: $navbar-height-xs + ($md-padding * 2);
$sm-border-radius: 5px;
$default-border-radius: 10px;

$complete-status: #066F5A;
$err-status: #C73831;
$wait-status: #D35400;
$white-red: #FDEFEE;
$white-orange: #FEF7D9;
$white-green: #E4FBE6;

// Other
$box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);

$signup-form-board: #95A5A6;

$navbar-width: 282px;

// For mobile view
$button-height-sm: 45px;

$err-status: #c73831;

// darkTheme colors
$dark-primary: #131313;
$dark-primary-text: $grey2;
$dark-primary-text-disabled: #95A5A6;
$dark-selected-background: #2a2a2a;
$tongo-primary-hover: $dark-selected-background;
$dark-secondary: #1C1C1C;
$dark-secondary-text: $grey2;
$dark-secondary-hover: #262930;

$dark-tongo-button-primary: $purple;
$dark-tongo-button-primary-text: $white;
$dark-tongo-button-primary-hover: #480BB7;
$dark-tongo-button-secondary: $dark-secondary;
$dark-tongo-button-secondary-border: $grey2;
$dark-tongo-button-secondary-text: $grey2;
$dark-tongo-button-secondary-hover: $dark-tongo-button-primary;
$dark-tongo-button-secondary-text-hover: $white;
$dark-tongo-button-tertiary: $white;
$dark-tongo-button-tertiary-text: $tongo-button-primary;
$dark-tongo-button-tertiary-disabled: #E0C2FF;
$dark-tongo-button-disabled: $grey2;
$dark-tongo-button-disabled-text: $grey5;
$dark-tongo-button-delete: #C73831;


$dark-secondary-card-info: #262930;
$dark-secondary-card-text: $grey2;

$dark-primary-card-progress-bar-buffer: #F5F5F5;
$dark-primary-card-progress-bar: #AD7BE8;

$dark-border: #262930;

$dark-info-message: #2980B9;
$dark-info-message-text: $white;
$dark-info-message-error: #E74C3C;

$dark-input-border: #95A5A6;
$dark-input-border-disabled: #262930;
$dark-input-border-focus: #3498DB;
$dark-input-border-hover: #3498DB;

// shadow
$shadow-login: rgba(237, 237, 237, 0.90);
