.mat-mdc-tab-link-container,
.mat-mdc-tab-label-container {
  margin-bottom: 0;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border: none;
  margin-bottom: 2rem;
}

.mat-mdc-link {
  &.mdc-link--active {
    opacity: 1;
  }
}

.mat-mdc-tab-labels {
  width: fit-content;
}

.mat-mdc-tab-links {
  width: fit-content;
}
.mat-mdc-tab-group:not(.default) {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    border-radius: 3rem;
    color: #261c04;
    font-weight: normal;
    height: 2.25rem !important;
    min-width: 2rem !important;
    opacity: 1 !important;
    padding: 0.5rem 1rem !important;
    overflow: hidden;

    &.mdc-tab--active, &.mdc-link--active {
       background: rgb(109 13 213 / 16%);

      .mdc-tab__content, .mdc-link__content {
        .mdc-tab__text-label, .mdc-link__text-label {
          color: $black;
          font-weight: 600;
        }
      }
    }

    .mdc-tab-indicator, .mdc-link-indicator {
       display: none;
    }

    .mdc-tab__content, .mdc-link__content {
      margin: auto;
      height: fit-content;
    }
  }
}

.mat-ink-bar {
  display: none;
  height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}
