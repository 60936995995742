.mat-toolbar {
  background-color: $white;
}

.mat-toolbar.mat-primary {
  background-color: $white;
  border-bottom: 1px solid $grey;
  margin: 25px 0 0 0;
  padding-bottom: 25px;
}

.menu-logo-toolbar {
  .menu-logo-toolbar-container {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .sidenav-sidenav__logo {
      height: 35px;
      margin-top: 5px;
    }
    .menu-button {
      height: 40px;
      width: 40px;
      min-width: 40px;
      margin: 0;
      border: 1px solid $grey6 !important;

      &.mdc-button.mat-mdc-button:not(:disabled) {
        color: $grey6;
      }

      .menu-button-close-icon {
        height: 40px;
        width: 40px;
        min-width: 40px;
        font-size: 38px;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.menu-logo-toolbar.layout-desktop {
  display: none;
}

// Dark Theme
.darkTheme {
  .mat-toolbar.mat-primary {
    background-color: $dark-primary;
  }

  .p-header__logo {
    filter: grayscale(1) invert(1);
  }
}
