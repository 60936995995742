@import "../settings/settings.mat-variables";

.mat-mdc-card .mat-mdc-card-header {
  color: #261c04;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.75rem;
  padding: 0;

  &.header-with-actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .mat-mdc-card-title {
    margin-bottom: 0;
  }

  .mat-mdc-card-header-text {
    margin-bottom: 1.5rem;
  }

  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-mdc-unelevated-button {
    //background-color: $tongo-button-secondary;
    //color: $tongo-button-secondary-text;
    //border: 1px solid $tongo-button-secondary-border !important;
    //border-radius: $default-button-border-radius;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;

    .mat-icon {
      color: $tongo-button-secondary-text;
    }

    &:first-of-type {
      margin-left: auto;
    }

    .mat-button-wrapper {
      align-items: center;
      display: flex;
    }
  }

  .mat-mdc-unelevated-button.icon-color {
    .mat-icon {
      color: $white;
      height: 16px;
      width: 15px;
    }
  }
  .mat-icon {
    height: 0.75rem;
    margin-right: 0.5rem;
    width: 0.75rem;
  }

  .material-icons {
    font-size: 1rem;
    user-select: none;
  }

  .mat-card-avatar {
    margin-right: 0.5rem;

    .mat-icon {
      color: #6d0dd5;
      height: 40px;
      margin: 0;
      width: 40px;
    }

    .material-icons {
      font-size: 40px;
    }
  }

  .mat-card-header-text {
    margin: 0;
  }
}

.card-header-with-buttons {
  margin-bottom: 1.5rem;
}

.filter-block {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    height: 35px !important;
  }

  mat-label {
    font-size: 14px;
    font-weight: 300;
  }

  .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-floating-label {
      top: 25px
  }

  .mat-form-field-hide-placeholder {
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-floating-label {
      top: 13px;
    }
  }

  .mdc-text-field--outlined .mdc-text-field__input {
    margin-top: 0.4rem;
    font-size: 14px;
    font-weight: 300;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
      padding-top: 0;
      padding-bottom: 0;
      height: 35px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.button-block {
  display: flex;
  flex-direction: row;;
  margin-left: auto;
}

mr-ngx-plaid-link-button {
  button.launch-plaid-link-button {
    background-color: $tongo-button-primary !important;
    color: $tongo-button-primary-text !important;
    border: 1px solid $tongo-button-primary !important;
    border-radius: $default-button-border-radius;
    margin: initial !important;
    height: initial !important;
    padding: 0.5rem 1rem !important;
    font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, 'IBM Plex Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif)) !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em) !important;

    &:hover {
      background-color: $tongo-button-primary-hover !important;
      border: 1px solid $tongo-button-primary-hover !important;
    }
  }
}

.card-header-with-controls {
  flex-direction: column;

  .card-header--controls {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
}

.agent-join-request-info {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background: $yellowBoxGround;
  color: $yellowBoxText;
  font-size: 14px;
  align-items: center;

  .agent-join-request-info-text {
    display: flex;
    align-items: center;

    .icon-size-20 {
      width: 20px;
      height: 20px;
      line-height: 19px;
    }
  }
}

.agents-invite-broker-link {
  padding: 24px;
  margin-top: 10px;
  border-radius: 5px;
  background: $grey7;
  color: $black;
  font-size: 14px;
  flex-direction: column;

  .invite-broker-link-info-text {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;

    .icon-size-20 {
      width: 20px;
      height: 20px;
      line-height: 19px;
    }
  }

  .copylink-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: $white;
    color: black;
    font-family: $font-family;

    .copylink-container-link-text {
      color: black;
      font-size: 15px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .copylink-link {
      display: flex;
      align-items: center;
      gap: 0;
      padding: 0 12px 0 10px;
      color: $dark-purple;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        color: white;
        .mat-icon {
          color: white;
        }
      }
      .mat-icon {
        width: 1.6rem;
        height: 1rem;
        transform: rotate(-45deg);
      }
    }
  }
}

@media (max-width: $mobile-screen) {
  .agent-join-request-info {
    padding: 16px;

    .agent-join-request-info-text {
      align-items: flex-start;
      margin-bottom: 1rem;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;

      .icon-size-20 {
        width: 32px;
      }
    }

    .card-header-buttons {
      .view-agent-join-requests-button {
        margin-bottom: 0;
      }
    }
  }

  .agents-invite-broker-link {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 0.7rem;

    .copylink-container {
      gap: 12px;

      .copylink-link {
        margin: 0;
      }
    }
  }
}

.mdc-button.mat-mdc-button.view-agent-join-requests-button:not(:disabled) {
  color: $yellowBoxText;
  border-color: $yellowBoxText !important;

  &:hover {
    background-color: $yellowBoxText;
  }
}

@media (max-width: $sm) {
  .card-header-with-controls {
    margin-bottom: 30px;

    .card-header--controls {
      margin-bottom: 20px;
    }

    .filter-block {
      margin-bottom: 24px;
      width: 100%;
    }

    .button-block {
      width: 100%;

      .mdc-button {
        width: 100%;
      }
    }

    .mat-mdc-unelevated-button.mat-mdc-button-base {
      margin-bottom: 0;
    }
  }
}
