.mat-button-toggle {
  box-sizing: border-box;
  font-size: 0.875rem;
  height: 2.25rem;
  min-width: 2rem;
  padding: 0.5rem 1rem;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border-radius: $default-button-border-radius !important;

  .mat-button-toggle-button {

  }
}

.mat-button-toggle-appearance-standard {
  .mat-button-toggle-label-content {
    line-height: 1;
    padding: 0 !important;
  }
}

.mat-button-toggle-checked {
  background: $tongo-button-primary;
  color: $tongo-button-primary-text;
  font-weight: 600;
}
