@import "../settings/settings.mat-variables";

.svg--b-on-w {
  height: 5.4rem;
  width: auto;
}

.login-page-wrapper {
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  background: $zero-state-bg2;
}

.login-page {
  background: $zero-state-bg2;
  display: flex;
  padding: 132px 180px 32px 200px;
  position: relative;

  .logo {
    z-index: 100;
    padding-right: 100px;
    max-width: 570px;

    img {
      width: 136px;
      height: 44px;
    }

    div {
      font-size: 72px;
      font-weight: 500;
      line-height: 110%;
      margin-top: 56px;
    }
  }

  .login-form {
    width: 500px;
    font-family: $font-family;
    margin-left: auto;
    padding: 70px 48px;
    border-radius: 16px;
    box-shadow: 0 0 35px 0 $shadow-login;
    z-index: 100;
    height: fit-content;

    .mat-mdc-card-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
    }

    .mat-mdc-card-content {
      display: flex;
      flex-direction: column;

      form {
        display: flex;
        flex-direction: column;

        .mat-error {
          margin: 0;
          color: $err-status;
          text-align: right;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }

        .password-input {
          margin-top: 16px;
          margin-bottom: 4px;
        }

        .mat-mdc-form-field {
          &:first-child {
            margin-top: 48px;
            margin-bottom: 4px;
          }
        }

        .mat-mdc-form-field-wrapper {
          padding-bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
        }

        .forgot-password-block {
          display: flex;
          align-items: center;
          margin-top: 4px;
          margin-bottom: 48px;

          .mat-mdc-checkbox {
            font-family: $font-family;
            color: $gray3;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
          }

          a {
            margin-left: auto;
            text-decoration: none;
            color: $purple;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
          }
        }

        .p-button.p-button--rounded {
          height: 46px;
          padding: 10px 16px;
          margin-bottom: 16px;

          &:disabled {
            border: none !important;
          }
        }
      }

      .signup-block {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 32px;

        a {
          margin-left: 8px;
          color: $purple;
          font-size: 14px;
        }
      }

      .policy {
        display: flex;
        flex-direction: row;
        gap: 32px;
        margin: auto;

        a {
          color: $dark-gray-color9;
          font-size: 11px;
          font-weight: 400;
          text-decoration-line: underline;
        }
      }
    }
  }

  .mat-mdc-card .mat-mdc-card-header .mat-mdc-card-header-text {
    margin-bottom: 0;
    width: 100%;
    text-align: center;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.img-houses {
  margin-top: auto;
  background-color: $zero-state-bg2;

  img {
    position: relative;
    width: 100vw;
    max-height: 259px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: $lg) {
  .login-page-wrapper {
    height: calc(100vh);
    display: flex;
    flex-direction: column;
    background-color: $bg-card;
  }
  
  .login-page {
    flex-direction: column;
    padding: 48px 0 0 0;
    height: unset;

    .logo {
      margin: 0 auto 48px auto;
      padding: 0;

      div {
        display: none;
      }
    }

    .login-form {
      margin-left: auto;
      margin-right: auto;
      padding: 50px 20px;
      min-width: unset;
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;

      .mat-mdc-card-title {
        margin-bottom: 0;
      }

      .mat-mdc-card-header-text {
        margin: 0 auto;
      }

      .mat-mdc-card-content {
        form {
          .mat-mdc-form-field {
            &:first-child {
              margin-top: 40px;
            }

            &:last-child.mat-mdc-form-field-wrapper {
              margin-bottom: 8px;
            }
          }

          .forgot-password-block {
            margin-bottom: 40px;
          }
        }

        .signup-block {
          margin-bottom: 24px;
        }
      }
    }
  }

  .img-houses {
    margin-top: auto;
    padding-top: 42px;
    min-height: 120px;
    overflow: hidden;

    img {
      position: relative;
      height: 120px;
      object-fit: cover;
    }
  }
}
