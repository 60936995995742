@import "../settings/settings.mat-variables";

.mat-mdc-card-actions {
  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-mdc-unelevated-button {
    box-sizing: border-box;
    min-width: 4rem;
    line-height: 2.25rem;
    margin: 0;
    border-radius: $default-button-border-radius;

    + .mat-button,
    + .mat-icon-button,
    + .mat-stroked-button,
    + .mat-mdc-unelevated-button {
      margin-left: 2rem;
    }
  }
}

.table-top-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  width: 100%;

  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-mdc-unelevated-button {
    & .mat-button,
    & .mat-icon-button,
    & .mat-stroked-button,
    & .mat-mdc-unelevated-button {
      margin-left: 1rem;
    }
  }
}

.mat-dialog-content--columns {
  min-width: 360px;
}

.button--table-columns {
  align-items: center;
  background: transparent;
  border: none;
  color: #757575;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: 1rem;
  margin-bottom: 1.34375em;

  .mat-icon {
    margin-left: 0.3em;
  }
}

.mdc-button .mdc-button__label {
  display: flex;

  .mat-mdc-progress-spinner {
    margin-right: 5px;
    margin-top: -3px;
  }
}

.mdc-button {
  background-color: $tongo-button-secondary;
  border: 1px solid $tongo-button-secondary-border !important;
  color: $tongo-button-secondary-text;
  border-radius: $default-button-border-radius;

  &:disabled {
    background: $tongo-button-disabled;
    color: $tongo-button-disabled-text;
    border: 1px solid $tongo-button-disabled !important;
  }

  &:not(:disabled):hover {
    background: $tongo-button-secondary-hover;
    color: $tongo-button-secondary-text-hover;
  }

  &.mat-mdc-button:not(:disabled) {
    color: $tongo-button-secondary-text;

    @media (max-width: $sm) {
      min-width: max-content;
      text-wrap: nowrap;
    }

    &:hover {
      color: $tongo-button-secondary-text-hover;
    }
  }
}

.mdc-button.mat-mdc-unelevated-button,
.mdc-button.mat-primary,
.mat-mdc-raised-button.btn-tongo-primary,
.mdc-button.mat-mdc-outlined-button {
  background-color: $tongo-button-primary;
  border: 1px solid $tongo-button-primary !important;
  color: $tongo-button-primary-text;
  border-radius: $default-button-border-radius;

  &:disabled {
    background: $tongo-button-disabled;
    color: $tongo-button-disabled-text;
    border: 1px solid $tongo-button-disabled !important;
  }

  &:not(:disabled) {
    background-color: $tongo-button-primary;
    color: $tongo-button-primary-text;
  }

  &:not(:disabled):hover {
    background: $tongo-button-primary-hover;
    border: 1px solid $tongo-button-primary-hover !important;
  }
}

.p-actions-remove {
  .mdc-button.mat-mdc-unelevated-button,
  .mdc-button.mat-primary,
  .mat-mdc-raised-button.btn-tongo-primary,
  .mdc-button.mat-mdc-outlined-button {
    background-color: $tongo-button-secondary;
    color: $tongo-button-delete;
    border: 1px solid $tongo-button-delete !important;

    &:not(:disabled) {
      background-color: $tongo-button-secondary;
      color: $tongo-button-delete;
      border: 1px solid $tongo-button-delete !important;
    }

    &:not(:disabled):hover {
      background-color: $tongo-button-delete;
      color: $tongo-button-primary-text;
      border: 1px solid $tongo-button-delete !important;
    }
  }
}

.mat-mdc-raised-button.btn-tongo-primary {
  font-weight: bold;
}

.button-link {
  padding: 0;
  min-width: 1rem;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: $sm) {
  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base,
  .mat-mdc-outlined-button.mat-mdc-button-base {
    height: $button-height-sm;
    margin-bottom: 1rem;

  }
}

// Dark Theme
.darkTheme {
  .mdc-button {
    background-color: $dark-tongo-button-secondary;
    border: 1px solid $dark-tongo-button-secondary-border !important;
    color: $dark-tongo-button-secondary-text;

    &:disabled {
      background: $dark-tongo-button-disabled;
      color: $dark-tongo-button-disabled-text;
      border: 1px solid $dark-tongo-button-disabled !important;
    }

    &:not(:disabled):hover {
      background: $dark-tongo-button-secondary-hover;
      color: $dark-tongo-button-secondary-text-hover;
      border: 1px solid $dark-tongo-button-primary !important;
    }

    &.mat-mdc-button:not(:disabled) {
      color: $dark-tongo-button-secondary-text;

      &:hover {
        color: $dark-tongo-button-secondary-text-hover;
      }
    }
  }

  .mdc-button.mat-mdc-unelevated-button,
  .mdc-button.mat-primary,
  .mat-mdc-raised-button.btn-tongo-primary,
  .mdc-button.mat-mdc-outlined-button {
    background-color: $dark-tongo-button-primary;
    border: 1px solid $dark-tongo-button-primary !important;
    color: $dark-tongo-button-primary-text;

    &:disabled {
      background: $dark-tongo-button-disabled;
      color: $dark-tongo-button-disabled-text;
      border: 1px solid $dark-tongo-button-disabled !important;
    }

    &:not(:disabled) {
      background-color: $dark-tongo-button-primary;
      color: $dark-tongo-button-primary-text;
    }

    &:not(:disabled):hover {
      background: $dark-tongo-button-primary-hover;
      border: 1px solid $dark-tongo-button-primary-hover !important;
    }
  }

  .p-actions-remove {
    .mdc-button.mat-mdc-unelevated-button,
    .mdc-button.mat-primary,
    .mat-mdc-raised-button.btn-tongo-primary,
    .mdc-button.mat-mdc-outlined-button {
      background-color: $dark-tongo-button-secondary;
      border: 1px solid $tongo-button-delete !important;
      color: $tongo-button-delete;

      &:not(:disabled) {
        background-color: $dark-tongo-button-secondary;
        color: $tongo-button-delete;
      }

      &:not(:disabled):hover {
        background-color: $tongo-button-delete !important;
        color: $dark-tongo-button-primary-text;
        border: 1px solid $tongo-button-delete !important;
      }
    }
  }
}

.remove-draft-commission-actions {
  .mdc-button.remove {
    background-color: $tongo-button-delete;
    border-color: $tongo-button-delete !important;

    &:hover {
      background-color: $tongo-button-primary-hover;
      border-color: $tongo-button-delete;
    }
  }
}

