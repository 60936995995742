@import "../settings/settings.mat-variables";

.mdc-switch--selected .mdc-switch__icon--on,
.mdc-switch--unselected .mdc-switch__icon--off {
  opacity: 0 !important;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked .mdc-form-field {
  color: $white !important;
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: $black;
}

.mat-mdc-slide-toggle.mat-primary {
  // unselected colors
  --mdc-switch-disabled-selected-handle-color: #9e9e9e;
  --mdc-switch-disabled-unselected-handle-color: #9e9e9e;
  --mdc-switch-disabled-selected-track-color: #9e9e9e;
  --mdc-switch-disabled-unselected-track-color: #9e9e9e;
  --mdc-switch-unselected-focus-state-layer-color: #9e9e9e;
  --mdc-switch-unselected-pressed-state-layer-color: #9e9e9e;
  --mdc-switch-unselected-hover-state-layer-color: #9e9e9e;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #fff;
  --mdc-switch-unselected-hover-handle-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #fff;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
  // selected colors
  --mdc-switch-selected-handle-color: #6d0dd5;
  --mdc-switch-selected-pressed-handle-color: #6d0dd5;
  --mdc-switch-selected-focus-handle-color: #6d0dd5;
  --mdc-switch-selected-hover-handle-color: #6d0dd5;
  --mdc-switch-selected-focus-track-color: #44107c;
  --mdc-switch-selected-hover-track-color: #44107c;
  --mdc-switch-selected-pressed-track-color: #44107c;
  --mdc-switch-selected-track-color: #44107c;
}

.mat-mdc-slide-toggle {
  .mdc-form-field--align-end>label {
    padding-right: 16px !important;
  }
}
