@import "../settings/settings.mat-variables";

.mat-mdc-table .mdc-data-table__row {
  height: 3.4rem;
}

.mat-mdc-table {
  th.mat-mdc-header-cell,
  td.mat-mdc-cell {
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 14px;
    font-weight: 400;
  }

  .mat-column-actionEdit {
    .agent-action {
      .agent-request-action-approve {
        width: 81px;
      }
    }
  }

  @media (max-width: $sm) {
    .mat-column-actionEdit {
      .agent-action {
        min-width: fit-content;
      }
      .statements-table-actions {
        width: 100%;

        div {
          width: auto;
        }
      }
      .administrators-action {
        min-width: fit-content;
      }
    }
  }
}

.mat-mdc-card {
  &.mat-card--table {
    overflow-x: scroll;
    padding: 0;
  }
}

mat-mdc-row,
mat-mdc-header-row,
mat-mdc-footer-row {
  border-bottom: none;
}

tr.mat-header-row {
  height: 3.2rem !important;
}

th.mat-header-cell:last-of-type, th.mat-mdc-header-cell:last-of-type, td.mat-mdc-cell:last-of-type, td.mat-mdc-footer-cell:last-of-type {
  padding-right: 24px;
}

th.mat-header-cell:first-of-type, th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type {
  // padding-left: 24px;
}

.mat-table .mat-header-row .mat-header-cell,
.mat-table .mat-table-row .mat-header-cell,
.mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell {
  color: $tongo-table-text-color;
  border-bottom: 1px solid $grey;
  font-size: 15px;
  line-height: 24px;
  font-family: $font-family;
  text-wrap: nowrap;
}
.mat-table .mat-header-row .mat-header-cell,
.mat-table .mat-table-row .mat-header-cell {
  font-weight: 600;
}
.mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell {
  font-weight: 700;
}
@media (max-width: $sm) {
  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-onboardingStatus,
  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-line1Address,
  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-phoneNumber, {
    min-width: 165px;
  }

  .mat-column-nickname {
    min-width: 170px;
  }

  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-paymentStatus,
  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-status, {
    min-width: 130px;
  }

  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-netCommission{
    min-width: 145px;
  }

  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-setting {
    min-width: 100px;
  }

  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell.mat-column-name {
    min-width: 152px;
  }
}

.mat-mdc-cell {
  align-items: baseline;
  padding: 1rem 0.75rem 0.5rem;
}

.mat-table--wide {
  border-collapse: collapse;
  margin-bottom: 1rem;

  .mat-header-cell {
    border-color: rgba(0, 0, 0, 0.12);
  }

  th.mat-header-cell,
  td.mat-cell {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 1rem 1rem 0.5rem;
    text-align: center;
  }

  th.mat-header-cell {
    .mat-icon {
      color: rgb(0 0 0 / 30%);
      display: block;
      margin: 0 auto 0.3rem;
      user-select: none;
    }
  }

  td.mat-cell {
    white-space: nowrap;
    border-color: rgba(0, 0, 0, 0.12);
    font-family: $font-family;
    font-size: 14px;

    &:not(:last-child) {
      border-right: 1px solid rgb(0 0 0 / 12%);
    }
  }
}

.table-info {
  .mat-header-cell {
    text-align: left;
    float: left;
    min-width: 100px;
    width: calc(100% - 3.5rem);

    &.mat-header-cell--short {
      min-width: 50px;
    }
  }

  th.mat-header-cell,
  td.mat-cell {
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
  }

  td.mat-cell {
    padding-left: 5px;
    line-height: 1px;
  }

  &.table-info--gray {
    background: $bg-card;
  }

  &.table-info--mb {
    margin-bottom: 20px;
  }
}

.table-card .table-info.table-info--mb {
  margin-bottom: 20px;
}

.table-button {
  background: $tongo-button-secondary;
  color: $tongo-button-secondary-text;
  border: 1px solid $tongo-button-secondary-border !important;
  border-radius: $default-button-border-radius;
  font-weight: 600;
  font-size: 0.875rem;

  /* height: 30px; */
  line-height: 1.25rem;
  padding: 0.5rem 1rem;

  &.mat-mdc-button-base.mat-primary {
    background: $tongo-button-primary;
    color: $tongo-button-primary-text;
    border: 1px solid $tongo-button-primary !important;

    &:hover {
      background-color: $tongo-button-primary-hover;
    }
  }

  &:hover {
    background-color: $tongo-button-secondary-hover;
    color: $tongo-button-secondary-text-hover;
  }

  &:not(.mat-accent):not(.mat-warn):not(.mat-primary) {
    background: $tongo-button-secondary;
    color: $tongo-button-secondary-text;
    border: 1px solid $tongo-button-secondary-border !important;
  }

  .mdc-button__label {
    white-space: nowrap;
  }
}

.p-actions-remove:not(.mat-accent):not(.mat-warn):not(.mat-primary) {
  .table-button {
    color: $tongo-button-delete;
    border-color: $tongo-button-delete !important;

    @media (max-width: $sm) {
      margin-bottom: 0;
      height: 36px
    }

    &:hover {
      color: $tongo-button-delete;
      border-color: $tongo-button-delete !important;
      color: $tongo-button-secondary-text-hover;
    }
  }
}

.table-link {
  cursor: pointer;
  text-decoration: underline;
  color: $primary-purple;
}

.color-primary {
  color: $primary-color;
}

.color-accent {
  color: $accent-color;
}

.color-warn {
  color: $warn-color;
}

.circle-icon-list {
  display: flex;
  flex-direction: row;

  &__item {
    background-color: #E0C2FF;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    line-height: 2.1;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    border: 2px solid white;
    margin-left: -6px
  }

  :first-child {
    margin-left: -2px
  }
}

.mat-mdc-table .mdc-data-table__header-row {
  height: 30px;
}

.mat-sort-header-content {
  p {
    margin-top: 0;
  }
}

.div-table {
  display: flex;
  width: 100%;

  .div-table-item {
    width: 100%;

    &:not(:first-child) {
      margin-left: 16px;
    }

    .table-header {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 14px;
    }

    .table-col {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.green-text-box {
  color: $greenBoxText;
  border-radius: 2px;
  background-color: $greenBoxGround;
  padding: 3px;
  display: table-cell;
}

.red-text-box {
  color: $redBoxText;
  border-radius: 2px;
  background-color: $redBoxGround;
  padding: 3px;
  display: table-cell;
}

.yellow-text-box {
  color: $yellowBoxText;
  border-radius: 2px;
  background-color: $yellowBoxGround;
  padding: 3px;
  display: table-cell;
}

.gray-text-box {
  color: $grayBoxText;
  border-radius: 2px;
  background-color: $grayBoxGround;
  padding: 3px;
  display: table-cell;
}

.primary-content {
  color: $primary-purple;
  border-radius: 48px;
  background-color: $grey7;
  padding: 4px 12px;
  display: flex;
  width: fit-content;
}

table {
  width: 100%;
  border-collapse: separate;
  border-radius: 4px;
}

.mat-table,
.mat-mdc-table {
  background-color: transparent !important;
  border: 1px solid $grey2 !important;

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type{
    padding-left: 16px;
  }
  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type{
    padding-right: 16px;
  }
}

.mat-mdc-table tr.mat-mdc-header-row {
  height: 3.4rem;
}

.mat-mdc-table th.mat-mdc-header-cell {
  border-bottom: 1px solid $grey2;
}

.expand-element-detail {
  background-color: $tongo-table-expand-background;
  padding: 0;

  table {
    border-collapse: collapse;
    margin-bottom: 0;
  }
}

// Dark Theme
.darkTheme {
  .mat-mdc-paginator {
    color: $dark-primary-text;
    background-color: $dark-primary;
  }

  .mat-mdc-select-value {
    color: $dark-primary-text;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $dark-primary-text !important;
  }

  .mat-mdc-select-arrow {
    color: $dark-primary-text;
  }

  .mdc-data-table__pagination-total, .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__cell {
    color: $dark-primary-text !important;
  }

  .color-primary {
    color: $dark-primary-text !important;
  }

  .mat-table,
  .mat-mdc-table {
    border: none !important;
    background-color: $dark-secondary !important;
  }

  .mat-table .mat-header-row .mat-header-cell,
  .mat-table .mat-table-row .mat-header-cell,
  .mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell,
  th.mat-mdc-header-cell,
  td.mat-mdc-cell {
    color: $dark-primary-text;
    border-bottom: 1px solid $dark-border;
  }

  .expand-element-detail {
    background-color: $dark-secondary;
  }

  .table-link {
    color: $dark-primary-text;
  }

  .circle-icon-list__item {
    background-color: $dark-primary-card-progress-bar;
  }

  .icon-svg {
    filter: grayscale(1) invert(1);
  }

  &.mat-mdc-select-panel {
    background-color: $dark-primary;
    border: 1px solid $dark-input-border;

    .mdc-list-item:not(.mdc-list-item--selected) .mdc-list-item__primary-text {
      color: $dark-primary-text;
    }

    .mat-mdc-option:hover:not(.mdc-list-item--disabled),
    .mat-mdc-option:focus:not(.mdc-list-item--disabled),
    .mat-mdc-option.mat-mdc-option-active,
    .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
      background-color: $purple;

      .mdc-list-item__primary-text {
        color: $white;
      }
    }
  }
}

.mat-column-isSelected {
  width: 55px;
}

.table-wrapper {
  overflow: auto;
  margin-right: -32px;
  padding-right: 32px;
  margin-left: -32px;
  padding-left: 32px;
}

.mat-elevation-z8.edit {
  .mat-column-firstName,
  .mat-column-lastName,
  .mat-column-phoneNumber,
  .mat-column-email {
    width: 19%;
  }

  .mat-column-status {
    width: 14%;
  }

  .mat-column-actions {
    width: 5%;
  }

  .mat-column-removed {
    width: 5%;
  }
}

.mat-elevation-z8.edit .mat-form-field {
  padding: 0 5px;
}

.mat-elevation-z8.edit .mat-mdc-text-field-wrapper {
  padding: 0 5px;

  .mat-mdc-form-field-infix {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    min-height: 0;
  }
}

.mat-elevation-z8.edit .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
