.dialog-without-padding .mat-dialog-container {
  padding: 0;
  width: 100%;
  max-width: 100%;
}

.mat-mdc-dialog-surface {
  padding: 32px;

  .mdc-dialog__title+.mdc-dialog__content {
    padding-left: 0;
    padding-right: 0;
  }

  .mdc-dialog__title {
    padding-left: 0;
    padding-right: 0;

    &::before {
      content: initial;
    }
  }

  .p-0-24.mdc-dialog__title {
    padding: 0 24px;
  }

  .mdc-dialog__actions {
    padding-left: 0;
    padding-right: 0;
  }

  .mat-mdc-dialog-content {
    .msg {
      margin-top: 2px;
      font-size: 16px;
      font-weight: 400 !important;
      color: $gray3;
    }

    .link {
      cursor: pointer;
      color:$primary-purple;
      text-decoration-line: underline;
    }
  }
}

.personal {
   .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      border: 1px solid $grey2;
      border-radius: 8px;
      background-color: $grey7;

      .mat-mdc-input-element  {
        padding-top: 8px;
      }

      .mdc-line-ripple {
        display: none;
      }
    }
  }
}

.personal-info-divider {
  margin: 20px 0 30px !important;
}

.mat-mdc-dialog-surface .mat-mdc-dialog-actions {
  justify-content: space-between;
}

.close-dialog-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .close-dialog-button {
    padding-top: 1rem;
    right: 0;
    text-align: right;

    .mat-icon-button .mat-icon {
      line-height: 1.4rem;
      height: 1.4rem;
      width: 1.4rem;
      font-size: 1.4rem;
    }
  }
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-weight: 600;

  .mdc-dialog__surface {
    overflow: hidden;
  }
}

.mdc-dialog .dialog-with-subheader.mat-mdc-dialog-content {
  padding-top: 0;

  .dialog-subheader {
    margin-bottom: 2rem;
  }
}

@media (max-width: $sm) {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    .mat-mdc-dialog-content {
      position: relative;

      .tile-container {
        width: auto;
        height: 120px;
      }

      .drag-drop-container {
        width: 100%;
      }

      .selected-file-container {
        min-width: 0;
        width: 230px;
      }
    }

    .dictionary-agents {
      min-width: 0;
    }

    .mat-mdc-dialog-actions {
      flex-wrap: nowrap;

      .mdc-button{
        min-width: 60px;
      }
    }
  }
}

.dialog-two-tiles {
  .mat-mdc-dialog-surface {
    padding: 24px;
  }

  .mat-mdc-dialog-title {
    color: $black;
    padding-bottom: 8px;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
  }

  .mdc-dialog .mdc-dialog__content {
    padding-bottom: 40px;
  }

  &__description {
    color: $black;
    margin-bottom: 40px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    border: 1px solid $grey2;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .dialog-tile-icon {
      min-width: 110px;
      min-height: 104px;
      display: flex;
      justify-content: center;
      background-color: $grey7;

      img {
        width: 40px;
      }
    }

    .dialog-tile-content {
      flex-grow: 1;
      line-height: 20px;
      padding: 16px 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: $black;

      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .dialog-tile-radio {
      padding: 16px;
    }

    &.selected {
      color: $primary-purple;
      border: 1px solid $primary-purple;
      background-color: $selectedLink;

      .dialog-tile-icon {
        background-color: $light-purple1;
      }
    }
  }

  .mdc-dialog__actions {
    padding: 0;
    min-height: unset;
    border: 0;
  }
}

@media (max-width: $mobile-screen) {
  .dialog-two-tiles {
    min-width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .mdc-dialog__actions {
      flex-direction: column;

      .mdc-button, .mat-mdc-button-base+.mat-mdc-button-base {
        margin-left: 0;
        width: 100%;
      }
    }

    &__item {
      .dialog-tile-radio {
        position: fixed;
        right: 50px;
      }
    }
  }

  .account-nickname-edit {
    .mat-mdc-dialog-content {
      margin-top: 20px;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field {
      height: 43px !important;
    }

    mat-label {
      font-size: 14px;
      font-weight: 300;
    }

    .mat-mdc-floating-label {
      top: 25px
    }

    .mat-form-field-hide-placeholder {
      .mat-mdc-floating-label {
        top: 13px;
      }
    }

    .mdc-text-field--outlined .mdc-text-field__input {
      margin-top: 0.5rem;
      font-size: 14px;
      font-weight: 300;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
      padding-top: 0;
      padding-bottom: 0;
      height: 35px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .all-agent-invite,
  .remove-draft-commission-actions,
  .account-nickname-edit,
  .default-bank-account,
  .remove-bank-account {
    max-width: 95vw !important;

    .mat-mdc-dialog-container {
      .mdc-dialog__title {
        font-size: 26px;
        font-weight: 500;
        line-height: 30px;
      }

      .mdc-dialog__content {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        padding-bottom: 2rem;
      }
    }

    .mdc-dialog__actions {
      flex-direction: column-reverse;

      .mdc-button, .mat-mdc-button-base+.mat-mdc-button-base {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

.dialog-upload-csv {
  .mat-mdc-dialog-container .mdc-dialog__title {
    padding-bottom: 8px;
  }

  .upload-csv--subtext {
    margin-bottom: 8px;
    color: $black;
  }

  .mdc-dialog .mdc-dialog__content {
    padding-bottom: 40px;
  }

  .mdc-dialog__actions {
    padding: 0;
    min-height: unset;
  }

  .drag-drop-container {
    width: 510px;
    height: 245px;
    border-radius: 5px;
    border: 1px solid $grey2;
    display: flex;
    flex-direction: column;
    margin: {
      top: 40px;
      bottom: 40px;
    }

    #select-csv-file {
      display: none;
    }

    &.dragging {
      background-color: rgba($turquoise, .5);
      cursor: pointer;
    }

    &--select-file {
      margin: auto auto 3px;
      font-size: 16px;
    }

    &--subtext {
      margin: 0 auto 5px;
      color: $black;
      font-size: 16px;
    }

    &--supported {
      margin: 0 auto auto;
      color: $asbestos;
      font-size: 12px;
    }

    &--not-supported {
      margin: 0 auto auto;
      color: $alizarin;
      font-size: 12px;
    }
  }

  .mat-mdc-dialog-container .mdc-dialog__surface .mat-mdc-dialog-content .selected-file-container {
    margin-bottom: 40px;
  }
}

@media (max-width: $sm) {
  .dialog-upload-csv {
    min-width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .mat-mdc-dialog-surface {
      padding: 24px;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface .mat-mdc-dialog-content .drag-drop-container {
      width: calc(100% - 2px);
    }

    .mat-mdc-dialog-actions{
      flex-direction: column;

      .mdc-button {
        width: 100%;
      }

      .mat-mdc-button-base+.mat-mdc-button-base {
        margin: 0;
      }
    }

    .mat-mdc-dialog-container .mdc-dialog__surface .mat-mdc-dialog-content .selected-file-container {
      width: calc(100% - 28px);
      margin-bottom: 40px;
    }
  }
}

.dialog-agent-join-requests, .dialog-agent-join-request-high-risk {
  mat-dialog-container {
    .mat-mdc-dialog-surface {
      .close-dialog-holder {
        justify-content: flex-end;
      }

      .mat-mdc-dialog-content {
        padding: 0 !important;

        h1.mat-mdc-dialog-title {
          font-size: 30px;
          font-weight: 700;
          line-height: 36px;
          text-align: center;
        }

        .review-agent-join-requests-description {
          text-align: center;
          line-height: 20px;
          color: $black;
          margin-block-start: 0;
          margin-block-end: 2px;
        }

        &.review-agent-join-requests-container {
          max-height: 100% !important;

          app-table.review-size {
            .table-wrapper{
              padding-top: 46px;
            }
          }

          app-table {
            .table-wrapper {
              margin-right: 0;
              padding-right: 0;
              margin-left: 0;
              padding-left: 0;
            }
          }
        }

        .review-agent-join-requests-img-houses {
          margin-bottom: 48px;

          img {
            position: relative;
            width: 100%;
            max-height: 237px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

.dialog-agent-join-request-match-existing {
  mat-dialog-container {
    .mat-mdc-dialog-surface {
      .mat-mdc-dialog-content {
        .dialog-two-tiles__list {
          display: flex;
          flex-direction: row;
          gap: 26px;

          .dialog-two-tiles__item {
            width: 50%;
            margin: 0;
            border-radius: 4px;

            .dialog-tile-content {
              padding: 0;
              gap: 0;

              .dialog-tile-content__title {
                padding: 18px 14px;
                color: $gray3;
                background-color: $grey7;
                border-radius: 4px;
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;

                &.color-green {
                  color: $greenBoxText;
                  background-color: $greenBoxGround;
                }
              }

              .dialog-tile-content__row {
                padding: 17px 10px;
                color: $gray3;
                border-top: 1px solid #D9D9D9;
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

// Dark Theme
.darkTheme {
  .mat-mdc-dialog-container {
    .mdc-dialog__title {
      color: $dark-primary-text;
    }
    .mdc-dialog__surface {
      color: $dark-primary-text;
      background-color: $dark-secondary !important;

      .mat-mdc-dialog-content {
        color: $dark-primary-text;
      }

      .mat-mdc-dialog-actions {
        background-color: $dark-secondary !important;
      }

      .tongo-link {
        color: $dark-primary-text;
      }

      .drag-drop-container--subtext {
        color: $dark-primary-text;
      }

      .selected-file-container span {
        color: $dark-primary-text;
      }
    }
  }
}
// DarkTheme
//.cdk-overlay-dark-backdrop {
//  background: rgba(0, 0, 0, 0.8);
//}

@media (max-width: $mobile-screen) {
  .dialog-confirm-actions {
    min-width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .mat-mdc-dialog-container .mdc-dialog__title {
      font-size: 26px;
      font-weight: 500;
      line-height: 30px;
      padding-bottom: 16px;
    }

    .mat-mdc-dialog-content {
      padding-bottom: 32px;
    }

    .mdc-dialog__actions {
      flex-direction: column;
      padding: 0;

      .mdc-button {
        width: 100%;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .dialog-agent-join-request-match-existing {
    max-width: 90vw !important;

    mat-dialog-container {
      .mat-mdc-dialog-surface {
        padding: 0;

        .mat-mdc-dialog-content {
          .mat-mdc-dialog-title {
            font-weight: 500;
            font-size: 26px;
            line-height: 30px;
            padding: 0;
          }

          .review-agent-join-requests-description {
            color: $black;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
          }

          .dialog-two-tiles__list {
            flex-direction: column;

            .dialog-two-tiles__item {
              width: 100%;
            }
          }
        }

        .mat-mdc-dialog-actions {
          flex-direction: column-reverse;

          .mdc-button {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }

          .actions {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
          }
        }
      }
    }
  }
}
