@import "../settings/settings.mat-variables";

.mat-drawer-container {
  background-color: #fff;
  height: 100%;
}

.mat-drawer-content.layout-desktop {
  overflow-x: hidden;
}

.mat-drawer {
  height: fit-content;
}

// Dart Theme
.darkTheme.mat-drawer-container {
  background-color: $dark-primary;
}
